*,
::after,
::before {
  box-sizing: border-box;
  outline: none;
}

html {
  font-size: 16px;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0rem !important;
  padding: 0rem !important;
  background-color: #f3f4f6;
}

body,
html {
  font-family: 'Roboto', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  margin: 0rem;
  line-height: initial;
}

iframe {
  border: none;
  width: 100%;
  min-height: 600px;
}

@media print {
  @page {
    size: A3;
  }

  .hiddenPrint {
    display: none !important;
  }

  .document_results {
    flex-wrap: wrap;
    gap: 1rem 0.5rem;
  }
}
