:root {
  --Snackbar-success: #047857;
  --Snackbar-info: #2563eb;
  --Snackbar-warning: #f59e0b;
  --Snackbar-danger: #c53030;
  --Snackbar-default: #4a5568;
  --Snackbar-fontColor: #333;
  --Snackbar-fontColorButton: #1d4ed8;
}

.notistack-SnackbarContainer {
  .notistack-CollapseWrapper {
    .notistack-Snackbar {
      .notistack-MuiContent {
        padding: 0;
        background: #fff !important;
        padding-right: 1.5rem;
        color: var(--Snackbar-fontColor);

        #notistack-snackbar {
          padding: 0;
          padding-right: 1rem;
          flex: 1;
          .svg {
            border-radius: 4px 0 0 4px;
            margin-right: 1rem;
            padding: 8px 8px 3px;
            height: 3.75rem;
            width: 3.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: #fff;
              height: 1.625rem;
              width: 1.625rem;
            }
          }
        }

        div {
          &:nth-child(2) {
            padding: 8px;
            padding-left: 15px;
            padding-right: 15px;
            margin-left: -17px;
          }
        }
      }
    }

    &[data-id='Snackbar-success'] {
      .notistack-Snackbar {
        .notistack-MuiContent {
          #notistack-snackbar {
            .svg {
              background: var(--Snackbar-success);
            }
          }
        }
      }
    }

    &[data-id='Snackbar-error'] {
      .notistack-Snackbar {
        .notistack-MuiContent {
          #notistack-snackbar {
            .svg {
              background: var(--Snackbar-danger);
            }
          }
        }
      }
    }

    &[data-id='Snackbar-info'] {
      .notistack-Snackbar {
        .notistack-MuiContent {
          #notistack-snackbar {
            .svg {
              background: var(--Snackbar-info);
            }
          }
        }
      }
    }

    &[data-id='Snackbar-warning'] {
      .notistack-Snackbar {
        .notistack-MuiContent {
          #notistack-snackbar {
            .svg {
              background: var(--Snackbar-warning);
            }
          }
        }
      }
    }

    &[data-id='Snackbar-default'] {
      .notistack-Snackbar {
        .notistack-MuiContent {
          background: var(--Snackbar-default) !important;
          color: #fff !important;
          padding-left: 1rem;
        }
        button[data-id='Snackbar-Close-Button'] {
          svg {
            margin-left: 1rem;
            width: 0.875rem;
            height: 0.875rem;
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }

    button[data-id='Snackbar-Go-Button'],
    button[data-id='Snackbar-Retry-Button'] {
      background: none !important;
      border: none !important;
      padding: 0;
      margin: 0;
      color: var(--Snackbar-fontColorButton);
      margin-right: 1rem;
    }

    button[data-id='Snackbar-Close-Button'] {
      background: none !important;
      border: none !important;
      padding: 0;
      margin: 0;
      .svg {
        border-left: 1px solid #e2e8f0;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-left: 1rem;
          width: 0.875rem;
          height: 0.875rem;
          path {
            fill: #718096;
          }
        }
      }
    }
  }
}
